import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { getLanguage, getText, requestThunk, ServerError, userSelectors } from "swiipe.portal.shared"
import { addModalThunk } from "../../store/thunks/modalThunks"
import { IBulkActionsResponse } from "../../type/IBulkActionsResponse"
import { IReportOrderActionData } from "../../type/IReportOrderActionData"
import { Order } from "../../type/Order"
import {
    CaptureTransactionResult,
    OrderTransaction,
    RefundTransactionResult,
    SucceededAtProviderOverride,
} from "../../type/Transaction"
import { StoreState } from "../StoreState"
import { merchantSelectors } from "../reducers/merchantReducer"
import { endpoints } from "./../../data/endpoints"
import { getPaymentOrderDetailsThunk } from "./orderThunks"

export const captureThunk =
    (
        webshopId: string,
        orderId: string,
        amount: number | null,
        reportData: IReportOrderActionData,
        onUpdate: (() => Promise<void>) | undefined
    ): ThunkAction<Promise<boolean>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        try {
            const resp = await dispatch(
                requestThunk<CaptureTransactionResult>(
                    endpoints.Payments.capture,
                    {
                        data: {
                            webshopId: webshopId,
                            orderId: orderId,
                            amountInMinors: amount === null ? null : Math.round(amount * 100),
                        },
                    },
                    {
                        noErrorAlert: true,
                    }
                )
            )

            onUpdate && (await onUpdate())

            if (!resp.success) {
                throw "failed"
            }

            if (resp.wasAlreadyCaptured) {
                dispatch(
                    addModalThunk({
                        type: "error",
                        title: getText("transactions.captureModal.failureTitle"),
                        errorMessage: getText("transactions.captureModal.failureAlreadyCapturedText"),
                    })
                )
                return false
            }

            dispatch(
                addModalThunk({
                    type: "info",
                    title: getText("transactions.captureModal.successTitle"),
                    text: getText("transactions.captureModal.successText"),
                    showCheckmark: true,
                })
            )

            return true
        } catch {
            dispatch(
                addTransactionActionFailedModalThunk(
                    getText("transactions.captureModal.failureTitle"),
                    getText("transactions.bulkActionModals.errors.captureError", { platformOrderId: reportData.platformOrderId }),
                    getText("transactions.action.capture"),
                    reportData
                )
            )
            return false
        }
    }

export const refundThunk =
    (
        webshopId: string,
        orderId: string,
        amount: number | null,
        reportData: IReportOrderActionData,
        onUpdate: (() => Promise<void>) | undefined
    ): ThunkAction<Promise<boolean>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        try {
            const resp = await dispatch(
                requestThunk<RefundTransactionResult>(
                    endpoints.Payments.refund,
                    {
                        data: {
                            webshopId: webshopId,
                            orderId: orderId,
                            amountInMinors: amount === null ? null : Math.round(amount * 100),
                            language: getLanguage(),
                            returnUrl: document.location.href,
                        },
                    },
                    {
                        noErrorAlert: true,
                    }
                )
            )

            if (resp.requiresRedirect && resp.redirectUrl) {
                document.location.href = resp.redirectUrl
                // Using iframe for A2A is currently not supported for all banks
                // dispatch(
                //     addModalThunk({
                //         type: "a2aRefund",
                //         url: resp.redirectUrl,
                //         onUpdate,
                //     })
                // )
                return true
            }

            onUpdate && (await onUpdate())

            if (!resp.success) {
                throw "failed"
            }

            dispatch(
                addModalThunk({
                    type: "info",
                    title: getText("transactions.refundModal.successTitle"),
                    text: getText("transactions.refundModal.successText"),
                    showCheckmark: true,
                })
            )

            return true
        } catch {
            dispatch(
                addTransactionActionFailedModalThunk(
                    getText("transactions.refundModal.failureTitle"),
                    getText("transactions.bulkActionModals.errors.refundError", { platformOrderId: reportData.platformOrderId }),
                    getText("transactions.action.refund"),
                    reportData
                )
            )

            return false
        }
    }

export type TBulkActionType = "Capture" | "Refund" | "Void"
export const bulkActionsThunk =
    (
        webshopId: string,
        orders: Order[],
        actionType: TBulkActionType
    ): ThunkAction<Promise<IBulkActionsResponse>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        return await dispatch(
            requestThunk<IBulkActionsResponse>(endpoints.Payments.bulk, {
                data: {
                    webshopId: webshopId,
                    orderIds: orders.map((o) => o.orderId),
                    action: actionType,
                },
            })
        )
    }

export const voidThunk =
    (
        webshopId: string,
        orderId: string,
        platformOrderId: string,
        reportData: IReportOrderActionData
    ): ThunkAction<Promise<boolean>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        try {
            const resp = await dispatch(
                requestThunk<CaptureTransactionResult>(
                    endpoints.Payments.void,
                    {
                        data: {
                            webshopId: webshopId,
                            orderId: orderId,
                        },
                    },
                    {
                        noErrorAlert: true,
                    }
                )
            )

            if (!resp.success) {
                throw "failed"
            }

            dispatch(
                addModalThunk({
                    type: "info",
                    title: getText("transactions.voidModal.successTitle"),
                    text: getText("transactions.voidModal.successText"),
                    showCheckmark: true,
                })
            )

            return true
        } catch {
            dispatch(
                addTransactionActionFailedModalThunk(
                    getText("transactions.voidModal.failureTitle"),
                    getText("transactions.bulkActionModals.errors.voidError", { platformOrderId }),
                    getText("transactions.action.cancel"),
                    reportData
                )
            )

            return false
        }
    }

export const reportActionFailedThunk =
    (
        action: string,
        comment: string,
        reportData: IReportOrderActionData
    ): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const user = userSelectors.userData(getState())
        const merchantDetails = merchantSelectors.merchantDetails(getState())

        if (!user || !merchantDetails) {
            return
        }

        const webshop = merchantDetails.webshops.find((w) => w.webshopId === reportData.webshopId)

        await dispatch(
            requestThunk<void>(endpoints.Payments.reportTransactionActionFail, {
                data: {
                    action: action,
                    comment: comment,
                    reportedByUser: user.user.email,
                    userName: user.user.fullName,
                    userPhone: user.user.phone,
                    merchantName: merchantDetails.swMerchant.name,
                    webshop: webshop?.hostName,
                    ...reportData,
                },
            })
        )
    }

export enum SettlementType {
    ClearhausSettlement = 0,
}

export interface IGetCsvTransformed {
    webshopId: string
    settlementType: SettlementType
    files: File[] | null
    exportAllData: boolean
}

export const getCsvTransformedFileThunk =
    (data: IGetCsvTransformed): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        const formData = new FormData()

        formData.append("webshopId", data.webshopId)
        formData.append("settlementType", data.settlementType.toString())
        formData.append("exportAllData", data.exportAllData.toString())

        if (data.files) {
            for (let i = 0; i < data.files.length; i++) {
                formData.append("files", data.files[i])
            }
        }

        const resp = await dispatch(
            requestThunk<any>(endpoints.Payments.getCsvTransformed, {
                data: formData,
                responseType: "blob",
            })
        )

        const url = window.URL.createObjectURL(new Blob([resp]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", "settlement.zip")
        document.body.appendChild(link)
        link.click()
        link.remove()
    }

export const addTransactionActionFailedModalThunk =
    (
        title: string,
        message: string,
        action: string,
        reportData: IReportOrderActionData
    ): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        dispatch(
            addModalThunk({
                type: "transactionActionError",
                title: title,
                errorMessage: message,
                action: action,
                reportData: reportData,
            })
        )
    }

export const updateTransactionIsOnlySucceededAtProvider =
    (
        webshopId: string,
        transaction: OrderTransaction,
        checked: boolean
    ): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        const modalMsg =
            checked === true
                ? "Are you sure you want to override this transaction status and mark it as successful at the provider?"
                : "Are you sure you want to stop overriding this transaction status?"

        const resp = await dispatch(
            addModalThunk({
                type: "modalDialog",
                mainTextHtml: modalMsg,
                acceptBtnText: "Yes",
                cancelBtnText: "No",
            })
        )

        if (resp.type != "accepted") {
            return
        }

        const isOnlySucceededAtProvider =
            checked === true ? SucceededAtProviderOverride.Enabled : SucceededAtProviderOverride.Disabled

        await dispatch(
            requestThunk(
                endpoints.Payments.adminUpdateTransaction(transaction.id),
                {
                    data: {
                        webshopId: webshopId,
                        isOnlySucceededAtProvider: isOnlySucceededAtProvider,
                    },
                },
                {
                    errorHandlers: [
                        {
                            handleError: async (err: ServerError) => {
                                await dispatch(
                                    addModalThunk({
                                        type: "error",
                                        title: "Failed to update transactions override settings",
                                        errorMessage: `${err.errorToAlert}`,
                                    })
                                )
                                return true
                            },
                        },
                    ],
                }
            )
        )

        await dispatch(getPaymentOrderDetailsThunk(webshopId, transaction.orderId, true))
    }
